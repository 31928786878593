<template>
    <div>
        <ts-page-title
            :title="$t('coaCategory.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('coaCategory.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t('addNew')
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('coaCategory.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="bspl">
                    <i-switch
                        v-tooltip="$t('coaSetup.isActive')"
                        size="small"
                        v-model="row.bspl"
                        :loading="row._switching"
                        @on-change="onChangeIsBspl(row)"
                        style="margin-right: 4px"
                    >
                    </i-switch>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('coaCategory.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import FromAction from './form'

export default {
    name: 'coaCategoryIndex',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            showForm: false,
            modal12: false,
            modal13: false,
            width: 'width: 300px'
        }
    },
    computed: {
        ...mapState('accounting/coaCategory', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.accounting.coaCategory.search
            },
            set (newValue) {
                this.$store.commit(
                    'accounting/coaCategory/SET_SEARCH',
                    newValue
                )
                this.$store.commit('accounting/coaCategory/RESET_CURRENT_PAGE')
            }
        },
        columns () {
            return [
                {
                    title: this.$t('coaCategory.nameEn'),
                    key: 'acc_cat_name_en',
                    sortable: true
                },
                {
                    title: this.$t('coaCategory.nameKh'),
                    key: 'acc_cat_name_kh',
                    sortable: true
                },
                {
                    title: this.$t('coaCategory.categoryType'),
                    key: 'acc_cat_type',
                    sortable: true
                },
                {
                    title: this.$t('coaCategory.reportOrder'),
                    key: 'report_order_id',
                    sortable: true
                },
                {
                    title: this.$t('coaCategory.bspl'),
                    slot: 'bspl',
                    align: 'center'
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('accounting/coaCategory/fetch', { ...attributes })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        onEdit (record) {
            this.$store.commit('accounting/coaCategory/SET_EDIT_DATA', record)
            this.showForm = true
            this.$refs.form_action.setEditData()
        },
        clearEdit () {
            this.showForm = false
            this.$refs.form_action.clearInput()
            this.$store.commit('accounting/coaCategory/SET_EDIT_DATA', {})
        },
        addNew () {
            this.$store.commit('accounting/coaCategory/SET_EDIT_DATA', {})
            this.showForm = true
            this.$refs.form_action.setEditData()
            this.$refs.form_action.clearInput()
        },
        async onChangeIsBspl(record) {
            record._switching = true;
            this.$store
                .dispatch("accounting/coaCategory/changeIsBspl", {
                    id: record.acc_cat_id,
                    data: { bspl: record.bspl }
                })
                .then(response => {
                    this.fetchData();
                    record._switching = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._switching = false;
                });
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'CoA CATEGORY',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('accounting/coaCategory/RESET_STATE')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
